import React from 'react';
import { Row, Col, Button, Card } from 'antd';
import './ServicesV2.css';
import { FORM_LINK } from './../constants';

const servicesData = [
    {
        title: "MVP Product",
        description: "Launch faster with tailored MVPs, using full-stack or no-code development suited to your needs."
    },
    {
        title: "Dedicated AI",
        description: "Embed secure, private AI into your existing workflows for enhanced automation and efficiency."
    },
    {
        title: "eKYC & Auth",
        description: "Automate KYC, User Authentication, Digilocker Services, Identity Verification."
    },
    {
        title: "AI videos automation",
        description: "Create better videos with smaller teams."
    },
    {
        title: "AI-driven Content Creation",
        description: "Automate instagram, youtube, and other social platforms."
    },
    // {
    //     title: "Custom AI Model Training",
    //     description: "AI model trained on your data and hosted on your cloud."
    // },
    {
        title: "WhatsApp AI Chatbot",
        description: "Automated customer support and engagement via WhatsApp."
    },
    {
        title: "UI/UX Optimization",
        description: "Transform ideas into intuitive wireframes and designs, ready for seamless development."
    },
    {
        title: "Web App Development",
        description: "End-to-end custom web solutions designed to meet your business objectives."
    },
    // {
    //     title: "Team Augmentation",
    //     description: "Enhance your development with skilled resources for any product stage, ensuring seamless project execution."
    // },
    {
        title: "Cloud and DevOps",
        description: "Optimize infrastructure with scalable cloud solutions and streamlined DevOps practices for efficient deployment."
    },
]

class ServicesV2 extends React.Component {
    render() {
        return (
            <div id='services-v2' className="services-section-container">
                <h2 className="section-heading" style={{ marginBottom: '30px' }}>What we do</h2>
                <Row gutter={[16, 16]} justify="center">
                    {servicesData.map((service, index) => (
                        <Col xs={24} sm={12} md={8} key={index}>
                            <Card hoverable className="service-card">
                                <h3 className="service-title">{service.title}</h3>
                                <p className="service-description">{service.description}</p>
                            </Card>
                        </Col>
                    ))}
                </Row>
                <div className="explore-button-container">
                    <Button type="primary" className="explore-button" target={'_blank'} href={FORM_LINK}>
                        Explore All Services
                    </Button>
                </div>
            </div>
        )
    }
};

export default ServicesV2;
