import React, { useState, useEffect, useRef } from "react";
import "./ScrollHideDiv.css";
import { Menu, Dropdown } from 'antd';
import { Link } from 'react-scroll';
import { getHeaderHeight } from './utils';
import { BASE_HOST } from './../constants';

const ScrollHideDiv = () => {
    const [showDiv, setShowDiv] = useState(true); // State to manage div visibility
    const [lastScrollY, setLastScrollY] = useState(0); // To store last scroll position

    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY && currentScrollY > 100) {
            // If we scrolled down and passed a point (e.g., 100px)
            setShowDiv(false);
        } else {
            // If we scrolled up
            setShowDiv(true);
        }

        setLastScrollY(currentScrollY);
    };

    const headerRef = useRef(null);
    const [headerHeight, setHeaderHeight] = useState(0);

    useEffect(() => {
        const updateHeaderHeight = () => {
            setHeaderHeight(getHeaderHeight());
        }

        updateHeaderHeight();
        window.addEventListener('resize', updateHeaderHeight);

        return () => {
            window.removeEventListener('resize', updateHeaderHeight);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll); // Cleanup listener on unmount
        };
    }, [lastScrollY]);

    const servicesDropdownMenu = (
        <Menu>
            <Menu.Item key="1">
                <a href={BASE_HOST + "?#services-v2"} rel="noopener noreferrer" offset={-headerHeight}>
                    Overview
                </a>
            </Menu.Item>
            <Menu.Item key="2">
                <a href={BASE_HOST + "/ekyc-rekyc-automation"} rel="noopener noreferrer" offset={-headerHeight}>
                    eKYC / reKYC
                </a>
            </Menu.Item>
            <Menu.Item key="2">
                <a href={BASE_HOST + "/ai-driven-automation"} rel="noopener noreferrer" offset={-headerHeight}>
                    Process Automation
                </a>
            </Menu.Item>
            <Menu.Item key="2">
                <a href={BASE_HOST + "/ai-driven-automation"} rel="noopener noreferrer" offset={-headerHeight}>
                    Gen-AI Solutions
                </a>
            </Menu.Item>
        </Menu>
    );

    return (
        // <div className={`top-div ${showDiv ? "visible" : "hidden"}`}>
        //     MaroonDev
        // </div>

        <div className={`top-page-content ${showDiv ? "with-top-div" : "without-top-div"}`}>
            {/* Top div that hides and shows based on scroll */}
            <div className={`top-div ${showDiv ? "visible" : "hidden"}`}>
                MaroonDev
            </div>

            {/* The rest of your content here */}
            <div className="top-main-content">
                <Menu theme="dark" mode="horizontal">
                    <Menu.Item key="1">
                        {/* <Link to="landing-page-v3" smooth={true} duration={500} offset={-headerHeight}>About us</Link> */}
                        <a href={BASE_HOST + "?#landing-page-4-container"} rel="noopener noreferrer" offset={-headerHeight}>
                            About us
                        </a>
                    </Menu.Item>
                    <Menu.Item key="2">
                        {/* <Link to="services-v2" smooth={true} duration={500} offset={-headerHeight}>What we do</Link> */}
                        <Dropdown overlay={servicesDropdownMenu} overlayClassName="custom-dropdown" placement="bottomCenter">
                            <span style={{ cursor: "pointer" }}>Solutions</span>
                        </Dropdown>
                    </Menu.Item>
                    <Menu.Item key="3">
                        {/* <Link to="tech-stack-v3" smooth={true} duration={500} offset={-headerHeight}>Stack</Link> */}
                        <a href={BASE_HOST + "?#tech-stack-v3"} rel="noopener noreferrer" offset={-headerHeight}>
                            Stack
                        </a>
                    </Menu.Item>
                    <Menu.Item key="4">
                        {/* <Link to="why-us-v2" smooth={true} duration={500} offset={-headerHeight}>Why us</Link> */}
                        <a href={BASE_HOST + "?#why-us-v2"} rel="noopener noreferrer" offset={-headerHeight}>
                            Why us
                        </a>
                    </Menu.Item>
                    <Menu.Item key="5">
                        {/* <Link to="how-we-work-v2" smooth={true} duration={500} offset={-headerHeight}>How we work</Link> */}
                        <a href={BASE_HOST + "?#how-we-work-v2"} rel="noopener noreferrer" offset={-headerHeight}>
                            How we work
                        </a>
                    </Menu.Item>
                    <Menu.Item key="6">
                        {/* <Link to="testimonials-v3" smooth={true} duration={500} offset={-headerHeight}>Testimonials</Link> */}
                        <a href={BASE_HOST + "?#testimonials-v3"} rel="noopener noreferrer" offset={-headerHeight}>
                            Testimonials
                        </a>
                    </Menu.Item>
                </Menu>
            </div>
        </div>

    );
};

export default ScrollHideDiv;
