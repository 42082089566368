import React from 'react';
import { Space, Button, Row, Col, Card, List } from 'antd';
import './../LandingPageV3.css';
import { FORM_LINK, BASE_HOST } from './../../constants';
import AnimatedSentence from './../AnimatedSentence';
import './EkycMainPage.css';

class EkycMainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          containerWidth: 0,
        };
        this.containerRef = React.createRef();
    }
    
    componentDidMount() {
        // Get the width of the parent container (Col or div) when the component is mounted
        this.setState({
            containerWidth: this.containerRef.current ? this.containerRef.current.offsetWidth : 0,
        });
    }

    render() {
        const layout = [
            { i: "description", x: 0, y: 0, w: 1, h: 1, static: true },
            { i: "image", x: 1, y: 1, w: 1, h: 1, static: true },
        ];

        const aiWorkForYouItems = [
            {
                "title": "360-degree approach",
            },
            {
                "title": "On-time projects delivery",
            },
            {
                "title": "Cutting-edge software",
            },
            {
                "title": "24/7 support system",
            },
        ]

        const whatWeOfferItems = [
            {
                "title": "360-degree approach",
            },
            {
                "title": "On-time projects delivery",
            },
            {
                "title": "Cutting-edge software",
            },
            {
                "title": "24/7 support system",
            },
        ]

        return (
            <div id='ekyc-main-page'>
                <Row className="ekyc-main-page-title">
                    <Col xs={24} md={24}>
                        <div>
                            <h2>E-KYC</h2>
                            <p>E-KYC (electronic Know Your Customer) is a digital process that lets businesses verify customer identities remotely. It involves collecting digital info, documents, and sometimes biometrics for verification. This speeds up onboarding, cuts paperwork, and enhances security while complying with regulations. Simplify paperless E-KYC with Aadhaar and Digi-Locker to simplify access to financial services. By automating account opening, our effective program lessens staff workload while facilitating easy trading and Demat account setup.</p>
                        </div>
                    </Col>
                </Row>
                <Row className="ekyc-main-page-what-we-offer">
                    <Col xs={24} md={12}>
                        <div>
                            <h2>What we offer</h2>
                            <p>E-KYC (electronic Know Your Customer) is a digital process that lets businesses verify customer identities remotely. It involves collecting digital info, documents, and sometimes biometrics for verification. This speeds up onboarding, cuts paperwork, and enhances security while complying with regulations. Simplify paperless E-KYC with Aadhaar and Digi-Locker to simplify access to financial services. By automating account opening, our effective program lessens staff workload while facilitating easy trading and Demat account setup.</p>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className="ekyc_what-we-offer-list">
                            <List
                                // itemLayout="vertical"
                                dataSource={whatWeOfferItems}
                                renderItem={(item) => (
                                    <List.Item className="ekyc_what-we-offer-list-item">
                                      <List.Item.Meta
                                        title={item.title}
                                        // description={item.description}
                                      />
                                    </List.Item>
                                  )}
                            />
                        </div>
                        {/* <Row gutter={[20, 20]} justify="center" className="ekyc_what-we-offer-items">
                            {whatWeOfferItems.map((item, index) => (
                                <Col xs={24} sm={12} md={5} key={index}>
                                    <Card hoverable className="ekyc_what-we-offer-item">
                                        <div>
                                            <h3>{item.title}</h3>
                                        </div>
                                    </Card>
                                </Col>
                            ))}
                        </Row> */}
                    </Col>
                </Row>
                <Row gutter={[20, 20]} justify="center" className="ekyc-main-page-items">
                    {aiWorkForYouItems.map((item, index) => (
                        <Col xs={24} sm={12} md={5} key={index}>
                            <Card hoverable className="ekyc-main-page-item">
                                <div>
                                    <h3>{item.title}</h3>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }
}

export default EkycMainPage;