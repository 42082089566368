import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./AnimatedSentenceV2.css";

const { Text } = Typography;

const AnimatedSentenceV2 = () => {
    const words = ["save time", "reduce costs", "grow faster"];
    const [currentWord, setCurrentWord] = useState(0);

    useEffect(() => {
        const wordInterval = setInterval(() => {
            setCurrentWord((prevWord) => (prevWord + 1) % words.length);
        }, 2000);

        return () => clearInterval(wordInterval);
    }, [words.length]);

    return (
        <div className="animated-sentence-container">
            {/* <Text className="sentence-text">
                {" "}
                <span className="animated-word">{words[currentWord]}</span><br/><span className="ai-auto-solutions">AI-driven Automation Solutions </span><br/>Quick & Precise!
            </Text> */}
            <Text className="sentence-text">
                <br/>
                <span className="ai-auto-solutions">Automate tasks, {" "}
                <span className="animated-word">{words[currentWord]}</span></span><br />

                {/* <span className="ai-auto-solutions">Automate tasks, <br/>save time, reduce costs <br/>and grow faster</span><br /> */}
                
                AI-enabled Automation Solutions
                {/* We believe access to neat and clean code should be a fundamental right of all businesses.  */}
                
                {/* We are here to raise the standards of the service industry. */}
                {/* Service work, done with product standards. */}

                {/* We believe that good code and software practices should be easily available to the service industry. We build your products like we have to scale them ourselves. */}
            </Text>
        </div>
    );
};

export default AnimatedSentenceV2;
