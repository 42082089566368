import React from 'react';
import './App.css';
import Home from './components/Home';
import ServicesV2 from './components/ServicesV2';
import TechStackV3 from './components/TechStackV3';
import WhyUsV2 from './components/WhyUsV2';
import HowWeWorkV2 from './components/HowWeWorkV2';
import Footer from './components/Footer';
import { Layout } from 'antd';
import TestimonialsV3 from './components/TestimonialsV3';
// import LandingPageV3 from './components/LandingPageV3';
import Stories from './components/Stories';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ChatApp from './components/ChatApp';
import ChatAppV2 from './components/ChatAppV2';
import FinancialServicesPage from './components/FinancialServicesPage';
import FinancialServicesPageV2 from './components/FinancialServicesPageV2';
import DragAndDropLayout from './components/DragAndDropLayout';
import MyFirstGrid from './components/GridLayout';
import AntdRowLayout from './components/AntdRowLayout';
import AIWorkForYou from './components/AIWorkForYou';
import LandingPageV4 from './components/LandingPageV4';

const { Content } = Layout;

function App() {
  return (
    <Router>
      <Layout className="App">
        <Home />
        <Content>
          {/* Define Routes */}
          <Routes>
            {/* Main Website Routes */}
            <Route
              path="/"
              element={
                <>
                  {/* <LandingPageV3 /> */}
                  <LandingPageV4 />
                  <AntdRowLayout />
                  <ServicesV2 />
                  <TechStackV3 />
                  <WhyUsV2 />
                  <HowWeWorkV2 />
                  <Stories />
                  <TestimonialsV3 />
                </>
              }
            />
            
            {/* Chat Page Route */}
            <Route path="/chat" element={<ChatApp />} />
            {/* Chat Page Route */}
            <Route path="/chat-v2" element={<ChatAppV2 />} />
            <Route 
              path="/ai-driven-automation"
              element={
                <>
                  <AIWorkForYou />
                  <FinancialServicesPageV2 />
                </>
              }
            />
            <Route
              path="/finserv-v2"
              element={
                <>
                  <FinancialServicesPageV2 />
                  <FinancialServicesPage />
                </>
              }
            />
            <Route
              path="/new-home"
              element={
                <>
                  <DragAndDropLayout />
                </>
              }
            />
            <Route
              path="/grid-layout"
              element={
                <>
                  <MyFirstGrid />
                  {/* <AntdRowLayout /> */}
                </>
              }
            />
            <Route
              path="/antd-row-layout"
              element={
                <>
                  <Home />
                  <AntdRowLayout />
                </>
              }
            />
          </Routes>
        </Content>
        
        {/* Footer */}
        <Footer />
      </Layout>
    </Router>
  );
}


export default App;
