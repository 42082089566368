import React from 'react';
import { Row, Col, Card, Avatar } from 'antd';
import './WhyUsV2.css';
import { FORM_LINK } from './../constants';

// const foundersData = [
//   {
//     name: 'Harsh Shukla',
//     title: 'Marketing Maverick',
//     description: 'Excels in GTM strategies, marketing automation, and lead generation. He builds scalable growth solutions for high-growth startups. Has led a campaign that generated $500,000 in revenue in just four months. Helped multiple startups go from 0 to 1.',
//     image: 'https://picsum.photos/200/300', // Replace with actual image URL
//   },
//   {
//     name: 'Rohit Khatee',
//     title: 'Business Enabler',
//     description: 'Excels in scaling businesses with tech using fullstack engineering and cloud solutions. Has a unique ability to convert a team into product shipping rockets. Helped scale companies like Zomato, Blinkit, ShopClues in their early high growth phases.',
//     image: 'https://picsum.photos/200/300', // Replace with actual image URL
//   },
//   {
//     name: 'Devansh Arora',
//     title: 'Automation Guru',
//     description: 'Specializes in optimizing processes, systems, and automation. He crafts solutions that enhance productivity and reduce costs. Helped in scaling Classplus from a seed company to a multi-million dollar business.  Implemented lead automation, saving ₹1.5 crore annually and scaling onboarding 3x.',
//     image: 'https://picsum.photos/200/300', // Replace with actual image URL
//   },
// ];

const foundersData = [
  {
    // name: 'Harsh Shukla',
    title: 'Business Thinking Stage',
    // description: 'Are you a business owner and in the early stages of shaping your business idea? Let’s brainstorm and define your product’s initial goals together. With my experience in driving product-led growth in high-growth, VC-backed startups, I can guide you effectively on this journey.',
    description: 'With my experience in driving product-led growth in high-growth, VC-backed startups, I can guide you effectively on this journey.',
    image: 'https://i.postimg.cc/RZMqjjCS/harshshukla.png', // Replace with actual image URL
    connect: "Connect with Harsh",
  },
  {
    // name: 'Devansh Arora',
    title: 'Product Thinking Stage',
    // description: 'Ready to transform your wireframes into actionable outcomes? I specialize in refining business logic workflows, and with my experience in scaling an edtech startup from the ground up to a $500 million valuation, I’m confident in adding value to your product development journey.',
    description: 'With my experience in scaling an edtech startup from the ground up to a $500 million valuation, I’m confident in adding value to your product development journey.',
    image: 'https://i.postimg.cc/VLPJFJ3b/devansharora.png', // Replace with actual image URL
    connect: "Connect with Devansh",
  },
  {
    // name: 'Rohit Khatee',
    title: 'Tech Development Stage',
    // description: 'Excels in scaling businesses with tech using fullstack engineering and cloud solutions. Has a unique ability to convert a team into product shipping rockets. Helped scale companies like Zomato, Blinkit, SalaryBox, ShopClues in their early high growth phases.',
    description: 'With my experience in scaling companies like Zomato, Blinkit, SalaryBox, ShopClues in their early high growth phases, I can help you drive growth and reduce costs with fullstack engineering, cloud and AI-driven solutions.',
    image: 'https://i.postimg.cc/nzkFx1Hy/Screenshot-2025-01-17-at-7-03-23-PM.png', // Replace with actual image URL
    connect: "Connect with Rohit",
  },
];

const WhyUsV2 = () => {
  return (
    <div id='why-us-v2' className="why-choose-us-container">
      <h2 className="section-heading">Why Choose Us?</h2>
      <p className="section-description">
      Connect with experts at MaroonDev at your current stage of product development. <br/>
      {/* At MaroonDev, we combine the latest technology with strategic expertise to deliver tailored solutions that drive real results. Our diverse team brings unique skills, proven experience, and a passion for innovation to every project. */}
      </p>
      <Row gutter={[16, 16]} justify="center">
        {foundersData.map((founder, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <Card hoverable className="founder-card">
              <Avatar size={150} src={founder.image} className="founder-avatar" />
              {/* <h3 className="founder-name">{founder.name}</h3> */}
              <p className="founder-title">{founder.title}</p>
              <p className="founder-description">{founder.description}</p>
              <a target={'_blank'} href={FORM_LINK}><p className="founder-connect dotted-underline">{founder.connect}</p></a>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default WhyUsV2;
