import React from 'react';
import { Space, Button, Row, Col } from 'antd';
// import './LandingPageV3.css';
import { FORM_LINK, BASE_HOST } from './../constants';
import AnimatedSentenceV2 from './AnimatedSentenceV2';
import './LandingPageV4.css';

class LandingPageV4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          containerWidth: 0,
        };
        this.containerRef = React.createRef();
    }
    
    componentDidMount() {
        // Get the width of the parent container (Col or div) when the component is mounted
        this.setState({
            containerWidth: this.containerRef.current ? this.containerRef.current.offsetWidth : 0,
        });
    }

    render() {
        const layout = [
            { i: "description", x: 0, y: 0, w: 1, h: 1, static: true },
            { i: "image", x: 1, y: 1, w: 1, h: 1, static: true },
        ];

        return (
            <div id='landing-page-4-container'>
                <Row style={{ height: '60vh'}} >
                    <Col xs={24} md={24} className="landing-text-whole">
                        <div>
                            <AnimatedSentenceV2 />
                        </div>
                    </Col>
                </Row>
                <Row style={{ height: '40vh'}} className="consult-button-row" >
                {/* <Button>Request demo</Button> */}
                    <div>
                        <Space>
                            <Button style={{ marginTop: '40px', padding: '30px', fontSize: '30px' }} type="primary" size="large" target={'_blank'} href={FORM_LINK}>
                                Consult Now
                            </Button>
                        </Space>
                    </div>
                </Row>
            </div>
        );
    }
}

export default LandingPageV4;