import React from 'react';
import { Row, Col, Card, Space, Button } from 'antd';
import './LandingPageV3.css';
import { FORM_LINK } from './../constants';
import AnimatedSentence from './AnimatedSentence';
import './FinancialServicesPageV2.css';
import './LandingPageV3.css';

class FinancialServicesPageV2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          containerWidth: 0,
        };
        this.containerRef = React.createRef();
    }
    
    componentDidMount() {
        // Get the width of the parent container (Col or div) when the component is mounted
        this.setState({
            containerWidth: this.containerRef.current ? this.containerRef.current.offsetWidth : 0,
        });
    }

    render() {

      const financeData = [
        {
            title: "eKYC / reKYC",
            description: "AI driven KYC process"
        },
        {
            title: "AI-driven Data Automation",
            description: "Automation with secure, private AI embedded into your existing workflows"
        },
        {
            title: "User Authentication",
            description: "Mobile / Email / SMS"
        },
        {
            title: "Identity Verification",
            description: "PAN, Aadhaar, Bank Account, Past Employment"
        },
        {
            title: "Digilocker Services",
            description: "AI-driven automatic data population from KRA/DigiLocker"
        },
        {
            title: "Document Upload & Digital Signature",
            description: "Automate all, no manual"
        },
      ]

      return (
          <div>
              <Row>
                <Col xs={24} md={24} className='finance-intro-heading'>
                  <h2 className="section-heading" style={{ marginBottom: '30px' }}>One stop solution for all things finance</h2>
                  <h3>We provide</h3>
                </Col>
              </Row>
              <Row>
                  <Col xs={24} md={24} className='finance-intro'>
                      <Row gutter={[40, 40]} justify="center">
                        {financeData.map((service, index) => (
                            <Col xs={24} sm={12} md={7} key={index}>
                                <Card hoverable className="fin-service-card">
                                    <h3 className="service-title">{service.title}</h3>
                                    <p className="service-description">{service.description}</p>
                                </Card>
                            </Col>
                        ))}
                      </Row>
                  </Col>
              </Row>
              <Row className='finance-intro-button'>
                <Space>
                  <Button style={{ marginTop: '40px', padding: '30px', fontSize: '30px' }} type="primary" size="large" target={'_blank'} href={FORM_LINK}>
                      Automate now
                  </Button>
                </Space>
              </Row>
          </div>
      );
    }
}

export default FinancialServicesPageV2;