import React, { useState, useEffect } from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Layout, Card } from 'antd';
import 'antd/dist/reset.css';
import './DragAndDropLayout.css'; // Custom CSS file for styling

const { Header: AntHeader, Footer: AntFooter, Content } = Layout;

// Reusable Header Component
const Header = () => (
  <AntHeader className="app-header">
    <h1>MaroonDev</h1>
    <p>Building reliable software solutions.</p>
  </AntHeader>
);

// Reusable Footer Component
const Footer = () => (
  <AntFooter className="app-footer">
    <p>© 2025 MaroonDev. All rights reserved.</p>
  </AntFooter>
);

// Reusable Content Components
const ContentBlock = ({ title, content }) => (
  <Card className="content-block" title={title} bordered={false} hoverable>
    <p>{content}</p>
  </Card>
);

const DragAndDropLayout = () => {
  // Load initial layout from localStorage or use default layout
  const defaultLayout = [
    { i: 'header', x: 0, y: 0, w: 12, h: 2, static: true },
    { i: 'block1', x: 0, y: 2, w: 6, h: 4 },
    { i: 'block2', x: 6, y: 2, w: 6, h: 4 },
    { i: 'block3', x: 0, y: 6, w: 12, h: 4 },
    { i: 'footer', x: 0, y: 10, w: 12, h: 2, static: true },
  ];

  const [layout, setLayout] = useState(() => {
    const savedLayout = localStorage.getItem('grid-layout');
    return savedLayout ? JSON.parse(savedLayout) : defaultLayout;
  });

  useEffect(() => {
    localStorage.setItem('grid-layout', JSON.stringify(layout));
  }, [layout]);

  return (
    <Layout className="app-layout">
      <GridLayout
        className="layout"
        layout={layout}
        cols={12}
        rowHeight={50}
        width={window.innerWidth}
        onLayoutChange={(newLayout) => setLayout(newLayout)}
        draggableHandle=".draggable"
      >
        <div key="header" className="grid-item">
          <Header />
        </div>
        <div key="block1" className="grid-item">
          <Content className="content-container">
            <ContentBlock
              title="Our Mission"
              content="At MaroonDev, we aim to deliver scalable and innovative software solutions to meet our clients' needs."
            />
          </Content>
        </div>
        <div key="block2" className="grid-item">
          <Content className="content-container">
            <ContentBlock
              title="Our Services"
              content="We specialize in custom software development, system architecture, and cloud solutions."
            />
          </Content>
        </div>
        <div key="block3" className="grid-item">
          <Content className="content-container">
            <ContentBlock
              title="Contact Us"
              content="Reach out to us at contact@maroondev.fyi for any inquiries or collaboration opportunities."
            />
          </Content>
        </div>
        <div key="footer" className="grid-item">
          <Footer />
        </div>
      </GridLayout>
    </Layout>
  );
};

export default DragAndDropLayout;