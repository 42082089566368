import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./AnimatedSentenceV2.css";

const { Text } = Typography;

const AnimatedSentenceV2 = () => {
    const words = ["save time", "reduce costs", "grow faster"];
    const [currentWord, setCurrentWord] = useState(0);

    useEffect(() => {
        const wordInterval = setInterval(() => {
            setCurrentWord((prevWord) => (prevWord + 1) % words.length);
        }, 2000);

        return () => clearInterval(wordInterval);
    }, [words.length]);

    return (
        <div className="animated-sentence-container">
            <Text className="sentence-text">
                <br/>
                <span className="ai-auto-solutions">Automate tasks, {" "}
                    <span className="animated-word">{words[currentWord]}</span>
                </span><br />                
                AI-enabled Automation Solutions
            </Text>
        </div>
    );
};

export default AnimatedSentenceV2;
