import React, { useEffect, useRef, useState } from 'react';
import { Typography, Layout } from 'antd';
// import { Link } from 'react-scroll';
import { getHeaderHeight } from './utils';
import ScrollHideDiv from './ScrollHideDiv';

// const { Title, Paragraph } = Typography;
const { Header, Content } = Layout;

const Home = () => {
  const headerRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  
  useEffect(() => {
    const updateHeaderHeight = () => {
        setHeaderHeight(getHeaderHeight());
    }

    updateHeaderHeight();
    window.addEventListener('resize', updateHeaderHeight);

    return () => {
        window.removeEventListener('resize', updateHeaderHeight);
    };
  }, []);

  return (
    <Layout>
      <Header ref={headerRef} className="home-header">
        <ScrollHideDiv className="home-content home-title"/>
      </Header>
    </Layout>
  );
}

export default Home;
