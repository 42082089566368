import React from 'react';
import { Row, Col, Button, Card } from 'antd';
import './HowWeWorkV2.css';
import { FORM_LINK } from './../constants';

const flowchartData = [
    {
        title: "Business Thinking",
        description: "Are the business use cases and goals for your product clearly defined? We brainstorm with you to shape your vision for product development.",
        helper: "or, here"
    },
    {
        title: "Product Thinking",
        description: "Are the wireframes and business logic workflows outlined? We create comprehensive product requirement documents (PRDs) for you.",
        helper: "or, here"
    },
    {
        title: "Product Design",
        description: "Is the product refined into replicable and user-friendly designs? We craft intuitive designs and wireframes that your audience can relate to.",
        helper: "or, here"
    },
    {
        title: "Scalable Tech Development",
        description: "Has your product made progress in development? We transform your ideas into a fully-functional product through our coding expertise.",
        helper: "or, here"
    },
]

const stepDetails = [
    {
        title: 'Step 1: Join an exploration call',
        // description: 'Start with a discovery call where you tell us about your business. We’ll identify your current stage—be it Business Thinking, Product Thinking, Product Design, or Scalable Tech Development. Together, we’ll discuss our approach, success criteria, timeline, budget, and the required skill sets to see how we can best assist you.',
    },
    {
        title: 'Step 2: Discuss solutions and engagement model',
        // description: 'Within a few days, we’ll finalize your project specifications and agree on an engagement model. This ensures you have a clear overview of the project and stay well-informed at every stage.',
    },
    {
        title: 'Step 3: Get started and track performance',
        // description: 'With milestones agreed upon, we’ll immediately begin work on your project. We’ll track progress, provide regular updates, and continuously adapt to meet your needs, ensuring a successful collaboration.',
    },
];

const HowWeWorkV2 = () => {
    // const handleScheduleCall = () => {
    //     window.location.href = '/schedule-call'; // Adjust path to your routing
    // };

    return (
        <div id='how-we-work-v2' className="how-we-work-container">
            <h2 className="section-heading">How We Work?</h2>
            <p className="section-description">
                At MaroonDev, we define our process by identifying the stage of your product, and accordingly suggest the services that would best suite. That said, it is always upto you at which exact stage you hire MaroonDev for custom software development.
            </p>

            <div className="flowchart">
                <Row gutter={[16, 16]} justify="center" align="middle">
                    {flowchartData.map((item, index) => (
                        <Col xs={24} sm={12} md={8} key={index}>
                            <div className="flowchart-item">
                                <span className="helper-text">{item.helper}</span>
                                <Card hoverable className="flowchart-card">
                                    <h3 className="flowchart-title">{item.title}</h3>
                                    <p className="flowchart-description">{item.description}</p>
                                </Card>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>

            <div className="step-details-container">
                {stepDetails.map((step, index) => (
                    <div className="step-detail" key={index}>
                        <h3 className="step-title">{step.title}</h3>
                        <p className="step-description">{step.description}</p>
                    </div>
                ))}
            </div>

            <div className="schedule-call-container">
                {/* <Button type="primary" className="schedule-call-button" onClick={handleScheduleCall}>
                    Schedule a Call
                </Button> */}
                <Button type="primary" className="schedule-call-button" target={'_blank'} href={FORM_LINK}>
                    Schedule a Call
                </Button>
            </div>
        </div>
    );
};

export default HowWeWorkV2;
