import React, { useState } from "react";
import { Button, Input, Card, Space, Spin } from "antd";
import { SendOutlined } from '@ant-design/icons';

const ChatAppV2 = () => {
  const [messages, setMessages] = useState([]); // Array to store the chat history
  const [userInput, setUserInput] = useState(""); // User's current input
  const [loading, setLoading] = useState(false); // Track loading state

  // Function to handle sending messages
  const sendMessage = async () => {
    if (!userInput.trim()) return; // Prevent sending empty messages

    // Add user message to the chat
    const userMessage = { sender: "user", text: userInput };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setLoading(true); // Show loading spinner

    try {
      // Call the FastAPI backend
      const response = await fetch("http://localhost:8000/chat/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt: userInput, max_length: 128, temperature: 0.7 }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Add the API's response to the chat
      const botMessage = { sender: "bot", text: data.reply };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error while sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Error: Unable to connect to the server." },
      ]);
    } finally {
      setLoading(false); // Hide loading spinner
    }

    setUserInput(""); // Clear the input field
  };

  // Handle "Enter" key press to send message
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      sendMessage(); // Send the message if Enter is pressed
    }
  };

  return (
    <div style={styles.container}>
      <Card style={styles.chatCard} bordered={false}>
        <div style={styles.chatBox}>
          {messages.map((msg, index) => (
            <div
              key={index}
              style={{
                ...styles.message,
                alignSelf: msg.sender === "user" ? "flex-end" : "flex-start",
                backgroundColor: msg.sender === "user" ? "#DCF8C6" : "#E6E6E6",
              }}
            >
              {msg.text}
            </div>
          ))}
          {loading && (
            <div style={styles.loadingMessage}>
              <Spin />
              <div>Bot is typing...</div>
            </div>
          )}
        </div>
        <div style={styles.inputContainer}>
          <Input
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder="Type your message..."
            style={styles.input}
            size="large"
          />
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={sendMessage}
            style={styles.sendButton}
            size="large"
          />
        </div>
      </Card>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f4f6f9",
  },
  chatCard: {
    width: "400px",
    maxHeight: "600px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },
  chatBox: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginBottom: "16px",
    overflowY: "auto",
  },
  message: {
    padding: "10px 15px",
    borderRadius: "12px",
    maxWidth: "70%",
    fontSize: "14px",
    lineHeight: "1.4",
    wordBreak: "break-word",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  input: {
    flex: 1,
    fontSize: "16px",
  },
  sendButton: {
    borderRadius: "50%",
    height: "40px",
    width: "40px",
  },
  loadingMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "12px",
    backgroundColor: "#f1f1f1",
    borderRadius: "12px",
  },
};

export default ChatAppV2;
