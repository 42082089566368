import React from 'react';
import { Space, Button, Row, Col } from 'antd';
import './LandingPageV3.css';
import { FORM_LINK, BASE_HOST } from './../constants';
import AnimatedSentence from './AnimatedSentence';
import './AntdRowLayout.css';

class AntdRowLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          containerWidth: 0,
        };
        this.containerRef = React.createRef();
    }
    
    componentDidMount() {
        // Get the width of the parent container (Col or div) when the component is mounted
        this.setState({
            containerWidth: this.containerRef.current ? this.containerRef.current.offsetWidth : 0,
        });
    }

    render() {
        const layout = [
            { i: "description", x: 0, y: 0, w: 1, h: 1, static: true },
            { i: "image", x: 1, y: 1, w: 1, h: 1, static: true },
        ];

        return (
            <div id='antd-row-layout'>
                <Row>
                    <Col xs={24} md={12} className="landing-text-side">
                        <div>
                            {/* <AnimatedSentence /> */}
                            <p className="left-paragraph">
                            We create dedicated on-premise AI solutions that help businesses optimize their internal processes and analytics.
                            <br></br><br></br>Automate workflows like <a target={'_blank'} href={BASE_HOST + "/finserv"} className="founder-connect dotted-underline">eKYC / reKYC</a>, enhance decision-making with AI-driven Analytics, optimize cost with on-premise AI support solutions.
                            <br></br><br></br>Seamlessly integrated in existing infrastructure and workflows while maintaining robust security and reliability.
                            </p>
                            <Space>
                                <Button style={{ marginTop: '40px', padding: '30px', fontSize: '30px' }} type="primary" size="large" target={'_blank'} href={FORM_LINK}>
                                    Let’s Talk
                                </Button>
                            </Space>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <div className='img-container'>
                            <a href="https://ibb.co/yfcBxpt">
                                {/* <img src="https://i.ibb.co/ngN8KB9/Whats-App-Image-2024-09-28-at-11-04-10-PM-1.jpg" alt="Whats-App-Image-2024-09-28-at-11-04-10-PM-1" border="0" /> */}
                                <img src="https://i.postimg.cc/xdcjRbLK/Whats-App-Image-2024-09-28-at-11-04-10-PM-1.jpg" alt="Whats-App-Image-2024-09-28-at-11-04-10-PM-1" border="0" />
                                {/* <img src="../assets/backgroundImages/ai-laptop-image.jpeg" alt="Whats-App-Image-2024-09-28-at-11-04-10-PM-1" border="0" /> */}
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AntdRowLayout;