import React from "react";
import { Typography, Row, Col, Button } from "antd";
import "./FinancialServicesPage.css";

const { Title, Text } = Typography;

const FinancialServicesPage = () => {
  return (
    <div className="financial-services-page">
      {/* Header Section */}
      <header className="header">
        <div className="header-content">
          <Title level={1} className="main-title">
            Financial Services, Redefined
          </Title>
          <Text className="sub-title">
            Simplify your operations with our comprehensive AI-driven e-KYC solutions.
          </Text>
          <Button type="primary" size="large" className="action-button">
            Get Started
          </Button>
        </div>
      </header>

      {/* Services Section */}
      <section className="fin-services">
        <Row gutter={[32, 32]}>
          <Col span={12}>
            <div className="fin-service-card">
              <Title level={3}>User Authentication</Title>
              <Text>
                Secure login with OTP-based verification and email authentication powered by Firebase.
              </Text>
            </div>
          </Col>
          <Col span={12}>
            <div className="fin-service-card">
              <Title level={3}>PAN & Aadhaar Validation</Title>
              <Text>
                DigiLocker integration ensures seamless document verification with fallback APIs.
              </Text>
            </div>
          </Col>
          <Col span={12}>
            <div className="fin-service-card">
              <Title level={3}>Bank Account Verification</Title>
              <Text>
                Reverse penny-drop and UPI-based validations for unmatched accuracy.
              </Text>
            </div>
          </Col>
          <Col span={12}>
            <div className="fin-service-card">
              <Title level={3}>AI-Driven Dashboard</Title>
              <Text>
                Real-time insights and automated workflows for enterprise-level performance.
              </Text>
            </div>
          </Col>
        </Row>
      </section>

      {/* Highlight Section */}
      <section className="highlight">
        <div className="highlight-content">
          <Title level={2} className="highlight-title">
            Designed for Enterprises
          </Title>
          <Text className="highlight-text">
            Scalable solutions built with compliance and performance at the core.
          </Text>
        </div>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <Row justify="space-between" align="middle">
          <Col span={12}>
            <Title level={3} className="footer-title">
              Let’s Build the Future Together
            </Title>
            <Text className="footer-text">
              Contact us today to explore the possibilities of transforming your financial workflows.
            </Text>
          </Col>
          <Col span={12} className="footer-actions">
            <Button type="primary" size="large" className="footer-button">
              Contact Us
            </Button>
          </Col>
        </Row>
      </footer>
    </div>
  );
};

export default FinancialServicesPage;
