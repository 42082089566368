import React from 'react';
import { Space, Button, Row, Col, Card } from 'antd';
import './LandingPageV3.css';
import { FORM_LINK, BASE_HOST } from './../constants';
import AnimatedSentence from './AnimatedSentence';
import './AIWorkForYou.css';

class AIWorkForYou extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          containerWidth: 0,
        };
        this.containerRef = React.createRef();
    }
    
    componentDidMount() {
        // Get the width of the parent container (Col or div) when the component is mounted
        this.setState({
            containerWidth: this.containerRef.current ? this.containerRef.current.offsetWidth : 0,
        });
    }

    render() {
        const layout = [
            { i: "description", x: 0, y: 0, w: 1, h: 1, static: true },
            { i: "image", x: 1, y: 1, w: 1, h: 1, static: true },
        ];

        const aiWorkForYouItems = [
            {
                "title": "360-degree approach",
            },
            {
                "title": "On-time projects delivery",
            },
            {
                "title": "Cutting-edge software",
            },
            {
                "title": "24/7 support system",
            },
        ]

        return (
            <div id='ai-work-for-you'>
                <Row className="ai-work-for-you-title">
                    <Col xs={24} md={16}>
                        <div>
                            <h2>Make AI work <br />for you</h2>
                            <p>use the power of artificial intelligence to automate your product growth for every sale.</p>
                        </div>
                    </Col>
                    <Col xs={24} md={8}>
                    </Col>
                </Row>
                <Row gutter={[20, 20]} justify="center" className="ai-work-for-you-items">
                    {aiWorkForYouItems.map((item, index) => (
                        <Col xs={24} sm={12} md={5} key={index}>
                            <Card hoverable className="ai-work-for-you-item">
                                <div>
                                    <h3>{item.title}</h3>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        );
    }
}

export default AIWorkForYou;